import axiosIns from "@/libs/axios";

const get = async () => {
  return await axiosIns.get(`sucursales`)
}

const store = async (data) => {
  return await axiosIns.post('sucursales', data)
}

const update = async (id, data) => {
  return await axiosIns.patch(`sucursales/${id}`, data)
}

const destroy = async (id) => {
  return await axiosIns.delete(`sucursales/${id}`)
}

const getDepartamentos = async () => {
  return await axiosIns.get(`departamentos`)
}

export default {
  get,
  store,
  update,
  destroy,

  getDepartamentos
}
